<!-------------------------------------------------
description:  发票详情 回退原因 详情组件
/**
 *@author xiaosanye 
 *@date 2023/2/11
*/
--------------------------------------------------->
<template>
  <ykc-detail class="box-card">
    <div slot="header" class="clearfix">
      <span>退回原因</span>
    </div>
    <ykc-detail-item label="退回原因" prop="name">
      <ykc-input readonly disabled v-model="info.reason" type="textarea"></ykc-input>
    </ykc-detail-item>
  </ykc-detail>
</template>

<script>
  import YkcInput from '@/components/ykc-ui/input/src/ykc-input.vue';
  import YkcDetail from '@/components/ykc-ui/detail/src/main.vue';
  import YkcDetailItem from '@/components/ykc-ui/detail-item/src/main.vue';

  export default {
    name: 'ReturnReason',
    components: { YkcDetailItem, YkcDetail, YkcInput },
    props: {
      info: { type: Object, default: () => ({}) },
    },
  };
</script>

<style scoped></style>
